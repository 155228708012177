import {
  faArrowsRotate,
  faCalendarDays,
  faChevronsLeft,
  faChevronsRight,
  faCircleArrowLeft,
  faCircleArrowRight,
  faCircleCheck,
  faCircleDown,
  faCircleEllipsisVertical,
  faCircleExclamation,
  faCircleHalfStroke,
  faCircleM,
  faCirclePlus,
  faCircleQuestion,
  faCircleTrash,
  faCircleUp,
  faCloudCheck,
  faCodeBranch,
  faDatabase,
  faFileExport,
  faFileImport,
  faFileXmark,
  faGlobe,
  faMemoCircleInfo,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faBallPile,
  faBell,
  faBrain,
  faBuildingColumns,
  faBuildings,
  faCalendarRange,
  faChartNetwork,
  faCheck,
  faChevronRight,
  faClipboardCheck,
  faEnvelope,
  faEnvelopeDot,
  faFileLines,
  faGripLines,
  faHeartPulse,
  faHospital,
  faHospitalUser,
  faLungs,
  faPerson,
  faPersonWalking,
  faQuestion,
  faRotate,
  faSitemap,
  faSnooze,
  faCircleCheck as faSolidCheck,
  faSquareList,
  faTriangleExclamation,
  faUser,
  faWatchFitness,
} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon, FontAwesomeIconProps} from '@fortawesome/react-fontawesome';
import {FC} from 'react';

export type IconComponent = FC<IconProps>;
export type IconProps = Omit<FontAwesomeIconProps, 'icon'>;

export function KIconGlobe(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faGlobe} />;
}

export function KIconCircleM(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faCircleM} />;
}

export function KIconBell(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faBell} />;
}

export function KIconContrast(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faCircleHalfStroke} />;
}

export function KIconUserSolid(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faUser} />;
}

export function KIconChartNetwork(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faChartNetwork} />;
}

export function KIconChevronsLeft(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faChevronsLeft} />;
}

export function KIconChevronsRight(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faChevronsRight} />;
}

export function KIconDatabase(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faDatabase} />;
}

export function KIconWarningSign(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faTriangleExclamation} />;
}

export function KIconOrganization(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faBuildings} />;
}

export function KIconSponsorColumns(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faBuildingColumns} />;
}

export function KIconStudy(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faCalendarRange} />;
}

export function KIconSite(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faHospital} />;
}

export function KIconChevronRight(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faChevronRight} />;
}

export function KIconCodeBranch(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faCodeBranch} />;
}

export function KIDetails(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faMemoCircleInfo} />;
}

export function KIconCircleDown(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faCircleDown} />;
}

export function KIconCircleUp(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faCircleUp} />;
}

export function KIconCirclePlus(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faCirclePlus} />;
}

export function KIconKnowledgeCenter(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faCircleQuestion} />;
}

export function KIconCalendarDays(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faCalendarDays} />;
}

export function KIconSitemap(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faSitemap} />;
}

export function KIconDrag(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faGripLines} />;
}

export function KIconEnvelopeDot(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faEnvelopeDot} />;
}

export function KIconEnvelopeFA(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faEnvelope} />;
}

/** todo might be a duplicate of raw/KIconCheck */
export function KIconCheckmark(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faCheck} />;
}

export function KIconBallPile(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faBallPile} />;
}
export function KIconSquareList(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faSquareList} />;
}

export function KIconPersonWalking(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faPersonWalking} />;
}
export function KIconLungs(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faLungs} />;
}

export function KIconRotate(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faRotate} />;
}

export function KIconBrain(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faBrain} />;
}

export function KIconHeartPulse(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faHeartPulse} />;
}

export function KIconPerson(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faPerson} />;
}

export function KIconSnooze(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faSnooze} />;
}

export function KIconQuestion(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faQuestion} />;
}

export function KIconClipboardCheck(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faClipboardCheck} />;
}

export function KIconFileLines(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faFileLines} />;
}

export function KIconWatchFitness(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faWatchFitness} />;
}

export function KIconVisits(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faHospitalUser} />;
}

export function KIconFileExport(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faFileExport} />;
}

export function KIconFileImport(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faFileImport} />;
}

export function KIconFileClear(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faFileXmark} />;
}

export function KIconMore(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faCircleEllipsisVertical} />;
}
export function KIconCircleArrowRight(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faCircleArrowRight} />;
}

export function KIconCircleArrowLeft(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faCircleArrowLeft} />;
}
export function KIconCloudCheck(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faCloudCheck} />;
}
export function KIconArrowsRotate(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faArrowsRotate} />;
}
export function KIconCircleExclamation(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faCircleExclamation} />;
}
export function KIconCircleCheck(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faCircleCheck} />;
}
export function KIconSolidCircleCheck(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faSolidCheck} />;
}
export function KIconCircleTrash(props: IconProps) {
  return <FontAwesomeIcon {...props} icon={faCircleTrash} />;
}
